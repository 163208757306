import React, { useState, useEffect, useRef } from 'react';
import './city-selector.css';

function Selector({ onSelect, selections, placeholder = 'Select an option' }) {
  const [filter, setFilter] = useState('');
  const [filteredSelections, setFilteredSelections] = useState(selections);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  // Debug the initial selections
  useEffect(() => {
    setFilteredSelections(selections);
  }, [selections]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const filtered = selections.filter(option =>
      typeof option === 'string' && option.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredSelections(filtered);
    // Debug the filtered selections
  }, [filter, selections]);

  const handleInputChange = (event) => {
    setFilter(event.target.value);
    setIsOpen(true);
  };

  const handleSelect = (selection) => {
    const selectedOption = selections.find(opt => opt === selection);
    if (selectedOption) {
      setFilter(selection); // Update the input field to show the selected option
      setIsOpen(false);
      onSelect(selectedOption); // Pass the entire object if needed elsewhere
    } else {
      console.error('Selected option is undefined.');
    }
  };
  

  return (
    <div ref={wrapperRef} className="city-selector-container">
      <input
        type="text"
        value={filter}
        onChange={handleInputChange}
        onFocus={() => setIsOpen(true)}
        placeholder={placeholder}
      />
      {isOpen && (
        <ul>
          {filteredSelections.map((option, index) => (
            <li key={index} onClick={() => handleSelect(option)}>
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
 

export default Selector;
