import React, { useEffect, useState } from "react";
import { formatCurrency } from "../../utils/currency-formatter";
import "./cost-comparison.css";

const CostComparison = ({ initialCost = "TBD", minCost = "-", maxCost = "-", marketComparison, category, userCost, guestCount }) => {
  const [cost, setCost] = useState(initialCost);
  const [low, setLow] = useState(0);
  const [average, setAverage] = useState(0);
  const [high, setHigh] = useState(0);
  const [costStructure, setCostStructure] = useState("per-event");

  useEffect(() => {
    if (marketComparison && marketComparison[category]) {
      let costData = marketComparison[category];
      setLow(costData.low);
      setHigh(costData.high);
      setAverage(costData.average);
      setCostStructure(costData.costStructure);
      if (costData.costStructure === "per-person") {
        setCost(userCost / guestCount);
      } else {
        setCost(userCost);
      }
      
    }
  }, [marketComparison, category, userCost, guestCount]);

  const calculatePosition = () => {
    if (low !== null && average !== null && high !== null) {
      const position = ((cost - low) / (high - low)) * 80 + 10; // adjust to fit within line
      if (position < 10) {
        return "10%";
      }

      if (position > 90) {
        return "90%";
      }

      return `${position}%`;
    }
    return "50%";
  };

  return (
    <div className="cost-comparison-container">
      <div className="comparison-bar">
        <span className="min-cost">
          <div className="cost-comparison-circle">{formatCurrency(low)}</div>
        </span>
        <div className="cost-comparison-line"></div>
        <div style={{ position: "relative", width: "100%" }}>
          <span
            className="cost-display"
            style={{ left: calculatePosition(), transform: `translateX(-${calculatePosition()})` }}
          >
            {formatCurrency(cost)}
            {costStructure === "per-person" ? "/person" : ""}
            
            <span className="triangle">▲</span>
          </span>
        </div>
        <span className="max-cost">
          <div className="cost-comparison-circle right">{formatCurrency(high)}</div>
        </span>
      </div>
    </div>
  );
};

export default CostComparison;
