import React from 'react';

const buttonStyle = {
  alignItems: 'center',
  backgroundColor: '#18181b',
  border: 'none',
  borderRadius: '100px',
  color: '#fff',
  display: 'block',
  margin: '0 auto',
  justifyContent: 'center',
  padding: '12px 40px',
  fontSize: '16px',
  lineHeight: '150%',
  fontFamily: 'var(--font-primary)',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
};


const WideButton = ({ children, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button style={buttonStyle} onClick={handleClick}>
      {children}
    </button>
  );
};

export default WideButton;
