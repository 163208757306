import React, { useState } from 'react';
import './event-selection.css';
import { venueOptions, venueOptionsValueMap } from '../../utils/onboarding-questions';
import Selector from '../selector/selector';

const EventLocation = ({ events }) => {
  console.log("EVENT LOCATIONS STEP", events);
  
  const [selection, setSelection] = useState('')

  return (
    <div className="event-location-step">
      {events.map((event, index) => (
        

        <div key={index} className="event-option">
          <p className='event-title'>{venueOptionsValueMap[event]} </p> 
            <Selector 
              className="event-selector"
              placeholder="Select preferred venue type ⬇️ " 
              selections={venueOptions}
              onSelect={value => {
                setSelection(value);
              }}
              value={selection}
            />

        </div>
      ))}
    </div>
  );
};

export default EventLocation;
