import React, { useState, useEffect, useRef, useCallback } from 'react';
import { debounce } from 'lodash';
import { apiService } from '../../../utils/apiService';
import { getUserId } from '../../../utils/get-userid';
import TaskRow from './TaskRow'; // Import TaskRow from its file
import './todo-list.css';
import { format, parse } from 'date-fns'; // For formatting and parsing dates
import CreatableSelect from 'react-select/creatable'; // Import CreatableSelect

const predefinedEventOptions = [
  'Welcome Event', 'Haldi', 'Sangeet', 'Wedding Ceremony', 'Reception'
].map(event => ({ value: event, label: event }));

const predefinedCategoryOptions = [
  'Accommodations', 'Attire & Jewellery', 'Catering', 'Decor', 'DJ', 'Finances', 'Guest Communications',
  'Hair & Makeup', 'Logistics', 'Mehndi', 'Photography', 'Transportation', 'Venue', 'Videography', 'Wedding Planner', 'Gift Exchange'
].map(category => ({ value: category, label: category }));

const predefinedTypeOptions = [
  'Personal', 'Work', 'Miscellaneous'
].map(type => ({ value: type, label: type }));

const TodoList = () => {
  const [todos, setTodos] = useState([]);
  const todosRef = useRef(todos);
  const [events] = useState(predefinedEventOptions);
  const [categories] = useState(predefinedCategoryOptions);
  const [types, setTypes] = useState(predefinedTypeOptions);
  const [openMonths, setOpenMonths] = useState({}); // Track which months are open
  const [newTaskByMonth, setNewTaskByMonth] = useState({}); // Track new tasks by month
  const [selectedTypes, setSelectedTypes] = useState([]); // Multi-select filter for task types
  const [selectedCategories, setSelectedCategories] = useState([]); // Multi-select filter for task categories

  const userId = getUserId();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const todoResponse = await apiService.get(`api/todo/${userId}`);
        if (todoResponse.success) {
          const todosData = todoResponse.data || [];
          setTodos(todosData);
          todosRef.current = todosData;
          const todoTypes = [...new Set(todosData.map(todo => todo.type))];
          setTypes(todoTypes.map(type => ({ value: type, label: type })));
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    fetchUserData();
  }, [userId]);

  // Group todos by the month of their dueDate
  const groupTodosByMonth = (todos) => {
    return todos.reduce((grouped, todo) => {
      if (todo.dueDate) {
        const monthKey = format(new Date(todo.dueDate), 'MMMM yyyy'); // Group by month name (e.g., 'August 2024')
        if (!grouped[monthKey]) {
          grouped[monthKey] = [];
        }
        grouped[monthKey].push(todo);
      }
      return grouped;
    }, {});
  };

  // Filter tasks based on selected filters
  const filteredTodos = todos.filter(task => {
    const matchesType = selectedTypes.length
      ? selectedTypes.some(type => type.value === task.type)
      : true;
    const matchesCategory = selectedCategories.length
      ? selectedCategories.some(category => category.value === task.category)
      : true;
    return matchesType && matchesCategory;
  });

  const groupedTodos = groupTodosByMonth(filteredTodos);

  // Toggle the accordion for each month
  const toggleMonth = (month) => {
    setOpenMonths(prevOpenMonths => ({
      ...prevOpenMonths,
      [month]: !prevOpenMonths[month]
    }));
  };

  // Debounced function to save the task to the server
  const debouncedSaveTask = useCallback(
    debounce(async (updatedTask) => {
      try {
        // API call to update the task
        const dataToUpdate = { ...updatedTask, taskId: updatedTask._id };
        await apiService.post('api/todo/updateTask', dataToUpdate);
        console.log("Task updated successfully");
      } catch (error) {
        console.error("Error updating task:", error);
      }
    }, 500), []); // 500ms debounce delay

  const handleTaskChange = (taskId, field, value) => {
    // Update the task in the local state
    setTodos(prevTodos => {
      const updatedTodos = prevTodos.map(task =>
        task._id === taskId ? { ...task, [field]: value } : task
      );
      todosRef.current = updatedTodos; // Update the reference
      return updatedTodos;
    });

    // Find the updated task by taskId
    const updatedTask = todosRef.current.find(task => task._id === taskId);

    if (updatedTask) {
      // Call the debounced function for the API update
      debouncedSaveTask(updatedTask); // Debounced API call
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      await apiService.post('api/todo/deleteTask', { userId, taskId });
      setTodos(prevTodos => prevTodos.filter(task => task?._id !== taskId));
      console.log("Task deleted successfully");
    } catch (error) {
      console.error("Failed to delete task:", error);
    }
  };

  const resizeTextArea = useCallback((textArea) => {
    if (textArea) {
      textArea.style.height = 'auto';
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }, []);

  // Handle creating a new task inline for a specific month
  const handleAddTask = async (month) => {
    const dueDate = parse(month, 'MMMM yyyy', new Date()); // Parse month as date
    
    // Create a new task object for this month
    const newTask = {
      task: '',
      type: 'Planning',
      category: 'General',
      progressNotes: '',
      proTip: '',
      dueDate,
      status: 'To-Do',
      userId
    };
  
    try {
      // Send the new task to the backend using your existing 'addTask' endpoint
      const response = await apiService.post('api/todo/addTask', newTask);
      
      if (response.success) {
        const savedTask = response.data;
        
        // Open the month accordion after task is added
        setOpenMonths(prevOpenMonths => ({
          ...prevOpenMonths,
          [month]: true
        }));
        
        // Add the newly created task to the list of tasks for this month
        setTodos(prevTodos => [savedTask, ...prevTodos]); // Add new task to local state
      } else {
        console.error('Failed to add task:', response.error);
      }
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

  return (
    <div className="todo-list">
      {/* Filter Section */}
      <div className="filter-container">
        <p>Filter by:</p>
        <div className="filter-dropdowns">
          <div className='filter-group'> 
            <label className='filter-label' htmlFor="type-filter">Type:</label>
            <CreatableSelect
              id="type-filter"
              isMulti
              value={selectedTypes || []}
              onChange={(selected) => setSelectedTypes(selected || [])}
              options={Array.from(new Set(todos.map(todo => todo.type))).map(type => ({ value: type, label: type }))}
            />
          </div>
          <div className='filter-group'> 

            <label className='filter-label' htmlFor="category-filter">Category:</label>
            <CreatableSelect
              id="category-filter"
              isMulti
              value={selectedCategories || []}
              onChange={(selected) => setSelectedCategories(selected || [])}
              options={Array.from(new Set(todos.map(todo => todo.category))).map(category => ({ value: category, label: category }))}
            />
            </div>
        </div>
      </div>
  
      {/* Accordion for tasks grouped by months */}
      {Object.keys(groupedTodos).map(month => (
        <div key={month} className="accordion-month">
          <div className="accordion-header" onClick={() => toggleMonth(month)}>
            <div className='accordion-header-left'> 
              <span className={`accordion-arrow ${openMonths[month] ? 'open' : ''}`}>
                {openMonths[month] ? '▲' : '▼'}
              </span>
              <h2 className='accordian-month'>{month}</h2>
            </div>
            <button className="add-task-button" onClick={(e) => { e.stopPropagation(); handleAddTask(month); }}>
              Add Task
            </button>
          </div>
          {openMonths[month] && (
            <div className="accordion-content">
              <table className="task-table">
                <thead>
                  <tr>
                    <th className='type-column'>Type</th>
                    <th className="expanded-task-column">Task</th>
                    <th className="category-column">Category</th>
                    <th className="notes-column">Notes</th>
                    <th className="status-column"></th>
                    <th className="delete-column"></th>
                  </tr>
                </thead>
                <tbody>
                  {groupedTodos[month].map(task => (
                    <TaskRow
                      key={task?._id}
                      task={task}
                      types={types}
                      categories={categories}
                      handleTaskChange={handleTaskChange}
                      resizeTextArea={resizeTextArea}
                      handleDelete={handleDeleteTask}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ))}
    </div>
  );
  
};

export default TodoList;
