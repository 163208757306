import React, { useState, useCallback, useEffect } from "react";
import CreatableSelect from 'react-select/creatable';
import { emojiEventMapping } from "../../utils/onboarding-questions";
import { formatCurrency } from "../../utils/currency-formatter";
import CostComparison from "./cost-comparison";
import { debounce } from "../../utils/debounce";
import { apiService } from "../../utils/apiService";
import "./cost-planner.css";

const departmentOptions = [
  { value: 'Catering', label: 'Catering' },
  { value: 'Venue', label: 'Venue' },
  { value: 'Decor', label: 'Decor' },
  { value: 'Photography', label: 'Photo' },
  { value: 'Video', label: 'Video' },
  { value: 'Alcohol', label: 'Alcohol' },
  { value: 'DJ', label: 'DJ' },
  { value: 'Hair & Makeup', label: 'Hair & Makeup' }
];

const vendorOptions = [
  { value: 'Vendor 1', label: 'Vendor 1' },
  { value: 'Vendor 2', label: 'Vendor 2' },
  { value: 'Vendor 3', label: 'Vendor 3' }
];

const CostPlanner = ({ eventInfo, setEventInfo, userInfo }) => {

  const [collapsedEvents, setCollapsedEvents] = useState({});
  const [editingEventName, setEditingEventName] = useState(null);
  const [eventNameInput, setEventNameInput] = useState("");
  const [marketComparison, setMarketComparison] = useState(null);


  useEffect(() => {

    const getMarketComparison = async () => {
      const marketComparisonData = await apiService.get(`api/marketComparison`);     
      setMarketComparison(marketComparisonData?.data);
    }

    getMarketComparison();

  }, []);

  const toggleCollapse = (eventKey) => {
    setCollapsedEvents({
      ...collapsedEvents,
      [eventKey]: !collapsedEvents[eventKey],
    });
  };

  const saveEventData = async (updatedEvents) => {
    const filteredEvents = { ...updatedEvents };
    Object.keys(filteredEvents).forEach((eventKey) => {
      filteredEvents[eventKey].costBreakdown = filteredEvents[eventKey].costBreakdown.filter(
        (row) => row?.category || row?.vendor || row?.cost
      );
    });

    try {
      const response = await apiService.post(`api/user/updateEvents`, {
        events: filteredEvents,
      });
      if (response.success) {
        console.log("Event data saved successfully");
      } else {
        console.error("Failed to save event data:", response.error);
      }
    } catch (error) {
      console.error("Error saving event data:", error);
    }
  };

  const debouncedSaveEventData = useCallback(debounce(saveEventData, 500), []);

  const handleInputChange = (eventKey, field, value, index) => {
    const updatedEvents = { ...eventInfo };
    const updatedEventDetails = [
      ...(updatedEvents[eventKey]?.costBreakdown || []),
    ];

    // Convert formatted currency to a number if the field is "cost"
    let formattedValue = value;
    if (field === "cost") {
      formattedValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
    }

    updatedEventDetails[index] = {
      ...updatedEventDetails[index],
      [field]: formattedValue,
    };
    updatedEvents[eventKey] = {
      ...updatedEvents[eventKey],
      costBreakdown: updatedEventDetails,
    };
    setEventInfo(updatedEvents);

    // Call the debounced function to save event data
    debouncedSaveEventData(updatedEvents);
  };

  const handleEventGuestCountChange = (eventKey, value) => {
    const updatedEvents = { ...eventInfo };
    updatedEvents[eventKey] = {
      ...updatedEvents[eventKey],
      guestCount: parseInt(value, 10),
    };
    setEventInfo(updatedEvents);
    debouncedSaveEventData(updatedEvents);
  };

  const addEventRow = (eventKey) => {
    const updatedEvents = { ...eventInfo };
    const updatedEventDetails = [
      ...(updatedEvents[eventKey]?.costBreakdown || []),
    ];

    // Add a new empty row
    updatedEventDetails.push({ category: "", vendor: "", cost: 0 });
    updatedEvents[eventKey] = {
      ...updatedEvents[eventKey],
      costBreakdown: updatedEventDetails,
    };
    setEventInfo(updatedEvents);
  };

  const addNewEvent = () => {
    const newEventKey = `newEvent_${Date.now()}`;
    const newEvent = {
      [newEventKey]: {
        name: "New Event",
        emoji: "🎉",
        costBreakdown: [
          { category: "Catering", vendor: "Vendor 1", cost: 5000 },
          { category: "Venue", vendor: "Vendor 2", cost: 2000 },
          { category: "Decor", vendor: "Vendor 3", cost: 1000 }
        ]
      }
    };

    setEventInfo({ ...newEvent, ...eventInfo });
  };

  const deleteEvent = async (eventKey) => {
    const updatedEvents = { ...eventInfo };
    delete updatedEvents[eventKey];

    setEventInfo(updatedEvents);

    try {
      const response = await apiService.post(`api/user/updateEvents`, {
        events: updatedEvents,
      });
      if (response.success) {
        console.log("Event deleted successfully");
      } else {
        console.error("Failed to delete event:", response.error);
      }
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const handleEventNameDoubleClick = (eventKey) => {
    setEditingEventName(eventKey);
    setEventNameInput(eventInfo[eventKey].name);
  };

  const handleEventNameChange = (e) => {
    setEventNameInput(e.target.value);
  };

  const handleEventNameBlur = (eventKey) => {
    const updatedEvents = { ...eventInfo };
    updatedEvents[eventKey].name = eventNameInput;
    setEventInfo(updatedEvents);
    setEditingEventName(null);
    saveEventData(updatedEvents);
  };

  const calculateEventTotal = (costBreakdown) => {
    return formatCurrency(
      costBreakdown.reduce(
        (acc, curr) => acc + (parseFloat(curr.cost) || 0),
        0
      )
    );
  };

  const calculateTotalCost = (events) => {
    if (events) {
      let totalCost = 0;

      Object.keys(events).forEach((eventId) => {
        const event = events[eventId];
        const eventTotal = (event.costBreakdown || []).reduce(
          (sum, item) => sum + item.cost,
          0
        );
        totalCost += eventTotal;
      });

      return formatCurrency(totalCost);
    }
    return 0;
  };

  return (
    <section className="cost-planner-section">
      <div className="dashboard-card-wrapper">
        <div className="dashboard-card expected-spend-card">
          <div className="dashboard-card-header">Expected Spend</div>
          <div className="expected-spend">{calculateTotalCost(eventInfo)}</div>
        </div>
        <div className="dashboard-card overall-budget-card">
          <div className="dashboard-card-header">Overall Budget</div>
          <div className="overall-budget">{formatCurrency(userInfo?.budgetEstimate)}</div>
        </div>

        <div className="add-event" onClick={addNewEvent}>
          <div className="add-event-button"> + </div>
          <div className="add-event-text"> Add Event </div>
        </div>
      </div>

      {eventInfo &&
        Object.keys(eventInfo).map((eventKey) => (
          <div key={eventKey} className="event-container">
            <div className="cost-planner-event-header">
              <button
                className={`toggle-button ${
                  collapsedEvents[eventKey] ? "collapsed" : ""
                }`}
                onClick={() => toggleCollapse(eventKey)}
              >
                {collapsedEvents[eventKey] ? "▼" : "▲"}
              </button>

              <div className="event-emoji"> {emojiEventMapping[eventKey] || '🎉'} </div>
              <div className="cost-planner-event-name">
                {editingEventName === eventKey ? (
                  <input
                    type="text"
                    className="edit-event-name-input"
                    value={eventNameInput}
                    onChange={handleEventNameChange}
                    onBlur={() => handleEventNameBlur(eventKey)}
                    autoFocus
                  />
                ) : (
                  <span onDoubleClick={() => handleEventNameDoubleClick(eventKey)}>
                    {eventInfo[eventKey].name}
                  </span>
                )}
              </div>
              <div className="cost-planner-event-total">
                <div> Event Total </div> 
                <div className='cost-planner-event-total-amount'> {calculateEventTotal(eventInfo[eventKey].costBreakdown) || "N/A"} </div>
              </div>
              <div className="cost-planner-event-guest-count">
                <div> Guest Count </div> 
                <input
                  className="guest-count-input"
                  type='number'
                  value={eventInfo[eventKey].guestCount || ""}
                  onChange={(e) => handleEventGuestCountChange(eventKey, e.target.value)}
                /> 
              </div>
              <button
                className="delete-event-button"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteEvent(eventKey);
                }}
              >
                ✕
              </button>
            </div>

            {!collapsedEvents[eventKey] && (
              <div className="event-details">
                <div className="event-table">
                  <div className="event-row header">
                    <div>Department</div>
                    <div>Vendor</div>
                    <div>Cost</div>
                    <div>Cost Comparison</div>
                  </div>
                  {(eventInfo[eventKey].costBreakdown || []).map(
                    (detail, index) => (
                      <div className="event-row" key={index}>
                        <CreatableSelect
                          options={departmentOptions}
                          value={
                            departmentOptions.find(option => option.value === detail.category) ||
                            { value: detail.category, label: detail.category }
                          }
                          onChange={(selectedOption) => handleInputChange(eventKey, "category", selectedOption ? selectedOption.value : '', index)}
                          placeholder="Department"
                          isCreatable={true}
                        />
                        <CreatableSelect
                          options={vendorOptions}
                          value={
                            vendorOptions.find(option => option.value === detail.vendor) ||
                            { value: detail.vendor, label: detail.vendor }
                          }
                          onChange={(selectedOption) => handleInputChange(eventKey, "vendor", selectedOption ? selectedOption.value : '', index)}
                          placeholder="Enter Vendor"
                          isCreatable={true}
                        />
                        <input
                          type="text"
                          value={formatCurrency(detail.cost) || ""}
                          onChange={(e) => handleInputChange(eventKey, "cost", e.target.value, index)}
                          placeholder="Cost"
                        />
                        <CostComparison marketComparison={marketComparison} category={detail.category} userCost={detail.cost} guestCount={eventInfo[eventKey].guestCount} />
                      </div>
                    )
                  )}
                </div>
                <button
                  className="add-expense-button"
                  onClick={() => addEventRow(eventKey)}
                >
                  +
                </button>
              </div>
            )}
          </div>
        ))}
    </section>
  );
};

export default CostPlanner;
