import { getUserId } from './get-userid';


const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';


async function fetchApi(endpoint, options = {}, authRequired = false) {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  // Append Authorization token if required
  if (authRequired) {
    const token = localStorage.getItem('authToken');
    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    } else {
      console.warn('Authorization required, but no token found');
    }
  }

  const config = {
    ...options,
    headers,
  };

  try {
    const response = await fetch(`${BASE_URL}/${endpoint}`, config);
    const data = await response.json();

    // Check if the response was not ok and throw an error
    if (!response.ok) {
      throw new Error(data.message || 'Something went wrong with the request');
    }

    return { success: true, data }; // Return data along with success status
  } catch (error) {
    console.error(`Error fetching data from ${endpoint}:`, error);
    return { success: false, error: error.message || 'An error occurred' }; // Handle errors gracefully
  }
}

export const apiService = {
  get: (endpoint, params = {}, authRequired = false) => {
    const url = new URL(`${BASE_URL}/${endpoint}`);
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    return fetchApi(endpoint, { method: 'GET' }, authRequired);
  },
  post: (endpoint, body, authRequired = false) => fetchApi(endpoint, { method: 'POST', body: JSON.stringify({ ...body, userId: getUserId() }) }, authRequired),
  put: (endpoint, body, authRequired = false) => fetchApi(endpoint, { method: 'PUT', body: JSON.stringify({ ...body, userId: getUserId() }) }, authRequired),
  delete: (endpoint, authRequired = false) => fetchApi(endpoint, { method: 'DELETE' }, authRequired),
};
