import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Onboarding from './components/onboarding/onboarding';
import { AuthProvider, useAuth } from './context/auth-context';
import Dashboard from './components/dashboard/dashboard';
import Login from './components/authentication/login';
import './index.css';


const RootRoute = () => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    // You can render a loading spinner or placeholder here
    return <div>Loading...</div>;
  }

  console.log('isAuthenticated:', isAuthenticated);

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  } else {
    return <Navigate to="/onboarding" />;
  }
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<RootRoute />} />
          <Route path="/login" element={<Login />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
