// OnboardingScreen component structure might look something like this
import React from 'react';
import './onboarding-screen.css';

function OnboardingScreen({ children }) {
    return (
        <div className="onboarding-container">
            <div className="onboarding-content">
                {children}  
            </div>
        </div>
    );
}

export default OnboardingScreen;
