
export const vendors = [
    "Catering",
    "Decor",
    "DJ",
    "Hair & Makeup",
    "Photo",
    "Video",
    "Bridal Hair & Makeup",
    "Alcohol",
];
