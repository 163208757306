import React from "react";
import EventDetails from "./event-details";
import TodoList from "./todo/TodoList";
import "./checklist.css";

const Checklist = () => {
  return (
    <>
        <div className="event-details-container"> 
            <EventDetails/>
        </div>
        <TodoList/>
    </>
  );
}

export default Checklist;;