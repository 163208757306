import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OnboardingScreen from '../screens/onboarding-screen';
import WideButton from '../buttons/wide-button';
import { onboarding, eventsQuestionId,  } from '../../utils/onboarding-questions';
import Selector from '../selector/selector';
import MultiSelect from '../multi-select/multi-select';
import { apiService } from '../../utils/apiService';
import EventSelection from './event-selection'
import { FaChevronCircleLeft } from "react-icons/fa";
import { getUserId } from '../../utils/get-userid';

import './onboarding.css';

const Input = ({ input, formData, setFormData, handleSelectionComplete }) => {
  switch (input.inputType) {
    case 'text':
    case 'email':
    case 'number':
    case 'password':
      return (
        <input
          type={input.inputType}
          name={input.name}
          value={formData[input.name] || ''}
          placeholder={input.placeholder}
          onChange={(e) => setFormData({
            ...formData,
            [input.name]: e.target.value
          })}
        />
      );
    case 'select':
      return (
        <Selector
          label={input.label}
          placeholder={input.placeholder}
          selections={input.options}
          onSelect={value => {
            setFormData({
              ...formData,
              questionNameId: input.nameId,
              [input.name]: value
            });
          }}
          value={formData[input.name]}
        />
      );
    case 'multi-select':
      return (
        <MultiSelect
          questions={input.options}
          maxSelections={input.maxSelections}
          onSelectionChange={(selectedOptions) => {
            handleSelectionComplete(input.name, selectedOptions, input.options)
          }}
          shouldSplit={input.split}
        />
      );
    case 'event-selection':
      const events = formData.map(question => eventsQuestionId === question.questionId ? question.answers : []).flat();
      return (
        <EventSelection
          events={events}
          onSelectionChange={(selectedOptions) => {
            handleSelectionComplete(input.name, selectedOptions)
          }}
          shouldSplit={input.split}
        />
      ); 
    case 'date': 
      return (
        <input
          type="date"
          name={input.name}
          value={formData[input.name] || ''}
          onChange={(e) => setFormData({
            ...formData,
            [input.name]: e.target.value
          })}
        />
      );



    default:
      return null;
  }
};

function Onboarding() {
  const navigate = useNavigate();
  const [onboardingQuestions, setOnboarding] = useState([])
  const [formData, setFormData] = useState({});
  const [currentScreenResponse, setCurrentScreenResponse] = useState('');
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectEventOptions, setSelectedEventsOptions] = useState([]);
  const [hasIndiaTrip, setHasIndiaTrip] = useState(false);
  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
  const currentScreen = onboardingQuestions[currentScreenIndex] || {};
  const userId = getUserId();


  useEffect(() => {
    const createUser = async () => {
      try {

        const result = await apiService.post('api/user/create', formData);
        if (result.success) {
          localStorage.setItem('userId', result.data._id);
        } else {
          console.error('Failed to save data:', result.error);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
    createUser();
    setOnboarding(onboarding);
  }, []);


  const navigateToNextStep = async () => {
    let userResponse = {};



    if (currentScreen.screenType !== 'info' && !currentScreen.collectEmailStep) {
      userResponse = {
        questionId: currentScreen.id,
        question: currentScreen.text,
        questionNameId: currentScreen.nameId,
        answers: currentScreenResponse,
        ...formData
      }
      const { success, data, error } = await apiService.post('api/onboarding', userResponse);
    }



    if (currentScreenIndex < onboardingQuestions.length - 1) {
      setCurrentScreenIndex(currentScreenIndex + 1);
    } 


    if (currentScreen.collectEmailStep) {
      try {
        const { success, data, error } = await apiService.post('api/user/setEmailPassword', {
          email: formData.email,
          password: formData.password
        });

        

        apiService.post(`api/todo/initialize`, { userId }, true);
        


        if (success && data.token) {
          localStorage.setItem('authToken', data.token);
          navigate('/dashboard');
        } else {
          console.error('Failed to save user:', error || 'No token received');
          // Handle error, e.g., show error message to the user
        }
      } catch (err) {
        console.error('Error:', err);
      }
    }
  }

  const handleClickBack = () => {
    if (currentScreen.nameId === 'invites' && !hasIndiaTrip) {
        setCurrentScreenIndex(currentScreenIndex - 2);
    }

    else if (currentScreenIndex > 0) {
      setCurrentScreenIndex(currentScreenIndex - 1);
    }


  };
    

  const handleSelectionComplete = async (name, selectedOptions, inputOptions=[]) => {
    if (name === "additionalEvents") {
      setSelectedEvents(selectedOptions);
      setSelectedEventsOptions(inputOptions)
    }
    setFormData({[name]: selectedOptions})
    setCurrentScreenResponse(selectedOptions)
  };



  if (currentScreen.screenType === 'event-selection') {

    return (
        <OnboardingScreen>
        <div className="onboarding-content">
          <h1 className='onboarding-heading'>{currentScreen.text}</h1>
          <div className='onboarding-form-wrapper'>
            <EventSelection events={selectedEvents}/>
          </div>
        </div>
        <div className='onboarding-bottom-button'>
          <WideButton onClick={navigateToNextStep}>Continue</WideButton>
        </div>
      </OnboardingScreen>
    )
  }
  
  else  {
    return (


      <OnboardingScreen>
        {currentScreenIndex !== 0 ? <div className='onboarding-back-button' onClick={handleClickBack}> <FaChevronCircleLeft/> </div> : null}



          <h1 className='onboarding-heading'>{currentScreen.text}</h1>

          {currentScreen.subText && (
            <p className="onboarding-subtext" dangerouslySetInnerHTML={{ __html: currentScreen.subText.replace(/\n/g, '<br />') }} /> 
          )}

          <div className='onboarding-form-wrapper'>
            {currentScreen.inputs?.map((input, index) => (
              <div key={index} className="onboarding-input">
                <Input input={input} formData={formData} setFormData={setFormData} handleSelectionComplete={handleSelectionComplete} />
              </div>
            ))}
          </div>
        
      
        {currentScreen.nameId === 'weddingDetails' && (
          <div className='onboarding-bottom-button'>
            <WideButton onClick={navigateToNextStep}>Unsure</WideButton>
          </div>
        
        )}

        <div className='onboarding-bottom-button'>
          <WideButton onClick={navigateToNextStep}>Continue</WideButton>
        </div>
      </OnboardingScreen>
    );
  }
}


export default Onboarding;
