import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './multi-select.css';

function MultiSelect({ questions, onSelectionChange, maxSelections, shouldSplit=false }) {


  const [selectedOptions, setSelectedOptions] = useState(new Set());

  useEffect(() => {
      setSelectedOptions(new Set());
  }, [questions])
  // Check if any question requires a split layout.


  function toggleOption(option) {
    const newSelections = new Set(selectedOptions);
    if (maxSelections === 1) {
      // For single selection, we either add the option if it's not already selected,
      // or we clear the selection if it's already selected.
      const newSet = new Set(selectedOptions.has(option) ? [] : [option]);
      setSelectedOptions(newSet);
      onSelectionChange(Array.from(newSet));
    } else {

      // For multiple selections, toggle the presence of option in the set.
      if (newSelections.has(option)) {
        newSelections.delete(option);
      } else if (!maxSelections || newSelections.size < maxSelections) {
        newSelections.add(option);
      }
      setSelectedOptions(newSelections);
      onSelectionChange(Array.from(newSelections));
    }
  }

  return (
    <div className={`multi-select-options ${shouldSplit ? "multi-select-options--split" : ""}`}>
      {questions && questions.length > 0 ? (
        questions.map((question) => (
          <button
            key={question.value}
            className={`multi-select-option ${selectedOptions.has(question.value) ? 'selected' : ''}`}
            onClick={() => toggleOption(question.value)}
            type="button"
          >
            {question.label}
          </button>
        ))
      ) : (
        <div>No questions available.</div>
      )}
    </div>
  );
}

MultiSelect.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
      split: PropTypes.bool
    })
  ).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  maxSelections: PropTypes.number
};

export default MultiSelect;
