import { apiService } from "../utils/apiService"
import { getUserId } from "../utils/get-userid";
export const getEventInfo = async () => {
      const userId = getUserId();
      const response = await apiService.get(`api/user/${userId}`);
      if (response.success) {
        const events = response.data?.events;
        return events 
      } else {
        console.error("Failed to fetch user info:", response.error);
      }

}