import React, { useState, useRef, memo, useCallback } from 'react';
import CreatableSelect from 'react-select/creatable';
import { debounce } from 'lodash';
import './todo-list.css';

const TaskRow = memo(({ task, types, categories, handleTaskChange, handleDelete }) => {
  const [notesContent, setNotesContent] = useState(task?.progressNotes || '');
  const [taskContent, setTaskContent] = useState(task?.task || '');
  const [taskType, setTaskType] = useState(task?.type || '');
  const [taskCategory, setTaskCategory] = useState(task?.category || '');
  const [status, setStatus] = useState(task?.status || 'To-Do');

  const notesTextAreaRef = useRef(null);
  const taskTextAreaRef = useRef(null);

  // Function to auto-resize textarea
  const resizeTextArea = useCallback((textarea) => {
    if (textarea) {
      textarea.style.height = 'auto'; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on scroll height
    }
  }, []);

  // Debounced function to handle task content changes
  const debouncedTaskContentChange = useCallback(
    debounce((taskId, value) => {
      handleTaskChange(taskId, 'task', value);
    }, 300),
    [handleTaskChange]
  );

  // Debounced function to handle notes content changes
  const debouncedNotesChange = useCallback(
    debounce((taskId, value) => {
      handleTaskChange(taskId, 'progressNotes', value);
    }, 300),
    [handleTaskChange]
  );

  // Handle task content changes
  const handleTaskContentChange = (e) => {
    const value = e.target.value;
    setTaskContent(value);
    debouncedTaskContentChange(task?._id, value);
    resizeTextArea(e.target);
  };

  // Handle notes content changes
  const handleNotesChange = (e) => {
    const value = e.target.value;
    setNotesContent(value);
    debouncedNotesChange(task?._id, value);
    resizeTextArea(e.target);
  };

  // Handle type change and save
  const handleTypeChange = useCallback((selectedOption) => {
    const value = selectedOption ? selectedOption.value : '';
    setTaskType(value);
    handleTaskChange(task?._id, 'type', value);
  }, [task?._id, handleTaskChange]);

  // Handle category change and save
  const handleCategoryChange = useCallback((selectedOption) => {
    const value = selectedOption ? selectedOption.value : '';
    setTaskCategory(value);
    handleTaskChange(task?._id, 'category', value);
  }, [task?._id, handleTaskChange]);

  // Handle status change and save
  const handleStatusChange = useCallback(() => {
    const newStatus = status === 'Completed' ? 'To-Do' : 'Completed';
    setStatus(newStatus);
    handleTaskChange(task?._id, 'status', newStatus);
  }, [status, task?._id, handleTaskChange]);

  return (
    <tr>
      <td>
        <CreatableSelect
          options={types}
          value={types.find(option => option.value === taskType) || { value: taskType, label: taskType }}
          onChange={handleTypeChange}
        />
      </td>
      <td>
        <textarea
          ref={taskTextAreaRef}
          className="expanded-task-textarea"
          value={taskContent}
          onChange={handleTaskContentChange}
          rows={1}
          style={{ overflow: 'hidden' }}
        />
      </td>
      <td>
        <CreatableSelect
          options={categories}
          value={categories.find(option => option.value === taskCategory) || { value: taskCategory, label: taskCategory }}
          onChange={handleCategoryChange}
        />
      </td>
      <td>
        <textarea
          ref={notesTextAreaRef}
          className="expanded-task-textarea"
          value={notesContent}
          onChange={handleNotesChange}
          rows={1}
          style={{ overflow: 'hidden' }}
        />
      </td>
      <td>
        <input
          type="checkbox"
          checked={status === 'Completed'}
          onChange={handleStatusChange}
        />
      </td>
      <td>
        <button className="delete-button" onClick={() => handleDelete(task?._id)}>
          🗑️
        </button>
      </td>
    </tr>
  );
});

export default TaskRow;
